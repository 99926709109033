<template>
    <div class="download-app-modal">
        <BaseModal v-if="isOpen"
                   :padding="isMobileOrTablet ? '16px': '32px'"
                   maxWidth="520px"
                   :useClickOutsideClose="!modalStore.isOnboarding"
                   :isClosable="!modalStore.isOnboarding"
                   @close="handleClose">
            <template #header/>
            <template #content>
                <div class="download-app-modal__content">
                    <img :src="asset_cdn('assets/missions/friends-2.svg')"
                         class="download-app-modal__content-image"
                         width="130"
                         :alt="getTrans('quests-dashboard.earnBits', 'Earn :number BITS', {number: 500})"
                         :title="getTrans('quests-dashboard.earnBits', 'Earn :number BITS', {number: 500})">
                    <div>
                        <div class="download-app-modal__subtitle">
                            <Translate translationKey="quests-dashboard.playOnYourSmartphone"
                                       fallback="Play on your Smartphone" />
                        </div>
                        <h2 class="download-app-modal__title">
                            🤑 <Translate translationKey="quests-dashboard.earnBits"
                                          fallback="Earn :number BITS"
                                          :replacements="{number: 500}" />
                        </h2>
                    </div>
                    <DownloadAppContent />
                    <div v-if="!isCurrentUrlOfCryptoApp && !modalStore.isOnboarding"
                         class="download-app-modal__actions">
                        <a :href="CRYPTO_APP_URL"
                           class="download-app-modal__link"
                           target="_blank"
                           rel="nofollow noopener noindex">
                            <Translate translationKey="quests-dashboard.learnHowItWorks"
                                       fallback=" Learn how it works" />
                        </a>
                    </div>
                </div>
            </template>
            <template #footer>
                <div v-if="modalStore.isOnboarding"
                     class="download-app-modal__actions">
                    <span class="download-app-modal__action--back">
                        <BaseButton :variant="ButtonVariantEnum.QUATERNARY"
                                    @click="handleClose">
                            <Translate translationKey="quests-dashboard.remindMeLater"
                                       fallback="Remind me later" />
                        </BaseButton>
                    </span>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import BaseModal from '@/js/components/BaseModal.vue';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useModalActive } from '@/js/composables/useModalActive';
    import { asset_cdn, route } from '@/js/helpers/helpers';
    import { getTrans } from '@/js/helpers/getTrans';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import DownloadAppContent from '@/js/global/header/components/DownloadAppContent.vue';
    import { ButtonVariantEnum } from '@/js/types/components/ButtonVariantEnum';
    import BaseButton from '@/js/components/BaseButton.vue';

    interface Props {
        isOpen: boolean;
    }
    defineProps<Props>();
    const emit = defineEmits<{(e: 'close'): void; }>();

    const modalStore = useModalStore();
    const { isMobileOrTablet } = useDeviceSize();

    const CRYPTO_APP_URL = route('crypto.mobile-app.index');
    const isCurrentUrlOfCryptoApp = computed(() => {
        const path = window.location.pathname;

        if (path === '/') {
            return false;
        }

        return CRYPTO_APP_URL.includes(path);
    });

    const handleClose = async () => {
        if (modalStore.isOnboarding) {
            emit('close');
        } else {
            modalStore.setDownloadAppModal(false);
        }
    };

    useModalActive();
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";
@import "@/sass/partials/variables";

.download-app-modal {
    &__content-image {
        display: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $baseWhite;
        gap: 24px;

        :deep(.download-app-content__content-image) {
            display: none;
            text-align: center;
        }
    }

    &__subtitle {
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
    }

    &__title {
        margin-bottom: 0;
        color: $warning500;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
    }

    &__link {
        color: $gray600;
        font-weight: 700;
        outline: none;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            color: $gray50;
        }
    }

    @media (max-width: $md-breakpoint-max-width) {
        &__content-image {
            display: block;
        }

        &__content {
            height: 99%;
            justify-content: center;
            gap: 36px;
        }

        &__actions {
            margin-top: 16px;
        }
    }
}
</style>
