<template>
    <div class="mobile-screen-icon-wrapper">
        <a v-if="!isReferralExist"
           :href="CRYPTO_REDIRECT_APP_URL"
           rel="nofollow noopener noindex"
           target="_blank"
           aria-label="Download Mobile App"
           class="mobile-screen-icon"
           @click.stop="preventNavigationOnCurrentPage">
            <i class="fa-solid fa-mobile-screen"
               :class="{ 'mobile-screen-icon--active': isSameRoute }" />
        </a>
    </div>
</template>

<script setup lang="ts">
    import {
        ref, onMounted, computed,
    } from 'vue';
    import { route } from '@/js/helpers/helpers';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useAuthStore } from '@/js/store/auth/AuthStore';

    const isSameRoute = ref(false);
    const CRYPTO_APP_URL = route('crypto.mobile-app.index');
    const CRYPTO_REDIRECT_APP_URL = route('crypto.mobile-app.redirect');
    const { isMobileOrTablet } = useDeviceSize();
    const modalStore = useModalStore();
    const authStore = useAuthStore();

    const isReferralExist = computed(() => authStore.isReferralCodeStored);

    const preventNavigationOnCurrentPage = (event: Event) => {
        if (isSameRoute.value || !isMobileOrTablet.value) {
            event.preventDefault();

            modalStore.setDownloadAppModal(true);
        }
    };

    onMounted(() => {
        const fullCurrentUrl = window.location.href;

        isSameRoute.value = fullCurrentUrl === CRYPTO_APP_URL;
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";

.mobile-screen-icon-wrapper {
    height: 36px;
}

.mobile-screen-icon {
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $blackcurrant;
    color: $titan-white;
    transition: color 200ms ease;

    i {
        font-size: 21px;
    }

    &:hover {
        color: $white;
        text-decoration: none;
    }

    &--active {
        color: $white;
    }
}
</style>
